import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectList from "../components/projectList"
import About from "../components/about"
import Contact from "../components/contact"
import Reel from "../components/reel"

const IndexPage = ({ location }) => {
  return (
    <Layout>
      <SEO title="David Gross — Composer, Songwriter &amp; Producer" />
      <Reel />
      <ProjectList />
      <About />
      <Contact />
    </Layout>
  )
}

export default IndexPage
