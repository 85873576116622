import React, { useState, useEffect } from "react"
import cx from "classnames"
import { useInView } from "react-intersection-observer"
import Image from "./image"
import Scroll from "react-scroll"

import styles from "./projectList.module.css"

// Content json
import projects from "../../content/projects.json"

let ScrollElement = Scroll.Element

const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 7.35 7.34"
    >
      <path d="M.00099026 6.63106168L6.4922305.13982143l.70710678.70710678L.70809704 7.33816847z" />
      <path d="M7.35 6.23h-1V1H1.13V0h6.22v6.23z" />
    </svg>
  )
}

const ProjectDetails = ({ project, index, setIndex }) => {
  const [ref, inView, entry] = useInView({
    threshold: [0.5],
  })

  useEffect(() => {
    if (inView) {
      setIndex(index)
    }
  }, [inView])

  return (
    <div className={styles.projectDetails} ref={ref}>
      <div className={styles.mobileProjectImage}>
        <Image className={styles.projectImage} filename={project.image} />
      </div>
      <span className={cx("mono", styles.projectTag)}>{project.tag}</span>
      <h2 className={cx("title", styles.projectTitle)}>{project.title}</h2>
      <h3 className={cx("title", styles.projectSubtitle)}>
        <em>{project.subtitle}</em>
      </h3>
      {project.buttons && (
        <div className={styles.buttons}>
          {project.buttons.map((button, i) => (
            <a
              key={`button${i}`}
              href={button.url}
              target="_blank"
              className={styles.button}
              rel="noopener noreferrer"
            >
              <span className="mono">{button.label}</span>
              <Arrow />
            </a>
          ))}
        </div>
      )}

      <ul className={styles.projectCredits}>
        {project.credits &&
          project.credits.map((credit, i) => (
            <li key={`credit${i}`} className={cx(styles.projectCredit)}>
              <span className={cx(styles.creditLabel, "mono")}>
                {credit.label}
              </span>
              {credit.url ? (
                <a
                  className={cx(styles.creditName)}
                  href={credit.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {credit.name}
                </a>
              ) : (
                <span className={styles.creditName}>{credit.name}</span>
              )}
            </li>
          ))}
      </ul>
      {project.laurels && (
        <div className={styles.projectLaurels}>
          {project.laurels &&
            project.laurels.map((laurel, i) => (
              <div key={`laurel${i}`} className={styles.projectLaurel}>
                <Image
                  filename={laurel}
                  style={{ height: "100%" }}
                  objectFit="contain"
                />
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

const ProjectList = () => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const setIndex = index => {
    setCurrentIndex(index)
  }

  return (
    <ScrollElement className={styles.projectList} name="projects">
      <div className={styles.projectDetailsColumn}>
        {projects.map((project, i) => (
          <ProjectDetails
            key={`project${i}`}
            index={i}
            project={project}
            setIndex={setIndex}
          />
        ))}
      </div>
      <div className={styles.projectImageColumn}>
        {projects.map((project, i) => (
          <div
            key={`projectImage${i}`}
            className={cx(
              styles.projectImageWrapper,
              currentIndex === i ? styles.reveal : ""
            )}
          >
            <Image
              className={styles.projectImage}
              filename={project.image}
              style={{ height: "90%" }}
              objectFit="contain"
              objectPosition="100% 50%"
            />
          </div>
        ))}
      </div>
    </ScrollElement>
  )
}

export default ProjectList
