import React from "react"
import Scroll from "react-scroll"

import Reveal from "./reveal"
import styles from "./about.module.css"

let ScrollElement = Scroll.Element

const About = () => {
  return (
    <ScrollElement name="about">
      <section className={styles.about}>
        <Reveal>
          <p className="title">
            David Gross is a composer and music producer based in Los Angeles.
            Music he created as a founding member of production duo{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://homevideomusic.com"
            >
              Home Video
            </a>{" "}
            has been featured on shows including <em>CSI</em>,{" "}
            <em>Gossip Girl</em>
            {", "}
            <em>Grey’s Anatomy</em>, and <em>Private Practice</em>. He’s
            per&shy;formed inter&shy;nationally as a tour&shy;ing key&shy;board
            player with bands{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://penguinprison.com"
            >
              Penguin Prison
            </a>
            ,{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.haertsmusic.com/"
            >
              HAERTS
            </a>
            , and{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://mndr.com"
            >
              MNDR
            </a>
            .
          </p>
        </Reveal>
      </section>
    </ScrollElement>
  )
}

About.propTypes = {}

About.defaultProps = {}

export default About
