/* analytics.js */
import Analytics from "analytics"
import googleAnalytics from "@analytics/google-analytics"

const analytics = Analytics({
  plugins: [
    googleAnalytics({
      trackingId: "UA-169339553-1",
    }),
  ],
})

// Set to global so analytics plugin will work with Gatsby
if (typeof window !== "undefined") {
  window.Analytics = analytics
}

/* export for consumption in your components for .track & .identify calls */
export default analytics
