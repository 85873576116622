import React from "react"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const Image = ({ filename, className, style, objectFit, objectPosition }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" } }) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 1600, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  const image = data.allFile.edges.find(n => {
    return n.node.base.includes(filename)
  })
  if (!image) {
    return null
  }

  return (
    <Img
      className={className}
      style={style}
      objectFit={objectFit}
      objectPosition={objectPosition}
      fluid={image.node.childImageSharp.fluid}
      alt={image.node.base.split(".")[0]} // only use section of the file extension with the filename
    />
  )
}

export default Image
