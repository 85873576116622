import React from "react"
import { useInView } from "react-intersection-observer"
import cx from "classnames"

import styles from "./reveal.module.css"

const Reveal = ({ children }) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
  })

  return (
    <div
      className={cx(styles.revealOnScroll, inView ? styles.reveal : "")}
      ref={ref}
    >
      {children}
    </div>
  )
}

export default Reveal
