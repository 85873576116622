import React from "react"
import Scroll from "react-scroll"

import Reveal from "./reveal"

import styles from "./contact.module.css"

let ScrollElement = Scroll.Element

const Contact = () => {
  return (
    <ScrollElement name="contact">
      <section className={styles.contact}>
        <Reveal>
          <a className="title" href="mailto:david@davidgross.studio">
            david
            <wbr />
            @davidgross
            <wbr />
            .studio
          </a>
        </Reveal>
      </section>
    </ScrollElement>
  )
}

export default Contact
