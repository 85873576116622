import PropTypes from "prop-types"
import React from "react"
import Scroll from "react-scroll"
import cx from "classnames"
import styles from "./header.module.css"

let ScrollLink = Scroll.Link

class Header extends React.Component {
  render() {
    return (
      <header className={styles.header}>
        <div className={styles.inner}>
          <div className={styles.titles}>
            <h1 className="mono">David Gross</h1>
            <h2 className="body">Composer, Songwriter &amp; Producer</h2>
          </div>
          <ul className={styles.menu}>
            <li className="mono">
              <ScrollLink
                to="projects"
                spy={true}
                hashSpy={true}
                smooth={"easeInOutQuart"}
                offset={0}
                duration={900}
              >
                Featured Projects
              </ScrollLink>
            </li>
            <li className="mono">
              <ScrollLink
                to="about"
                spy={true}
                hashSpy={true}
                smooth={"easeInOutQuart"}
                offset={0}
                duration={900}
              >
                About
              </ScrollLink>
            </li>
            <li className="mono">
              <ScrollLink
                to="contact"
                spy={true}
                hashSpy={true}
                smooth={"easeInOutQuart"}
                offset={0}
                duration={900}
              >
                Contact
              </ScrollLink>
            </li>
          </ul>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `David Gross`,
}

export default Header
