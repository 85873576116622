import React from "react"
import Scroll from "react-scroll"

import Reveal from "./reveal"
import styles from "./reel.module.css"

let ScrollElement = Scroll.Element

const Reel = () => {
  return (
    <ScrollElement name="reel">
      <section className={styles.reel}>
        <Reveal>
          <div className={styles.videoContainer}>
            <iframe
              src="https://player.vimeo.com/video/900466467?h=274e950784&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              title="David Gross: Music Composition Reel"
              className={styles.videoIframe}
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </Reveal>
      </section>
    </ScrollElement>
  )
}

Reel.propTypes = {}

Reel.defaultProps = {}

export default Reel
